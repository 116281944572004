.search-content {
  a {
    color: var(--design-color-surface-action-primary-default);
    box-shadow: none;
  }
}

.search-input {
  div {
    width: 100%;
    position: relative;
  }

  label {
    font-weight: bold;
  }

  input {
    border-radius: var(--design-border-radius-lg);
    margin-right: var(--design-spacing-xs);
    padding-left: 45px;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
}
